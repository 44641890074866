import React from 'react';
import styled from 'styled-components';
import MailchimpSubscribe from "react-mailchimp-subscribe";

const CustomForm = ({ status, message, onValidated, modalTrigger }) => {

  let email, name, occupation, modal;
  const submit = () =>
    email &&
    name &&
    email.value.indexOf("@") > -1 &&
    occupation &&
    modal &&
    onValidated({
      EMAIL: email.value,
      NAME: name.value,
      OCCUPATION: occupation.value,
      MODAL: modal.value
    });

  return (
    <div className="mailchimpForm"
    >
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <input ref={node => (modal = node)} type="hidden" value={modalTrigger} />
      <input
        ref={node => (name = node)}
        type="text"
        placeholder="NAME*"
      />
      <input
        ref={node => (occupation = node)}
        type="text"
        placeholder="ORGANIZATION*"
      />
      <input
        ref={node => (email = node)}
        type="email"
        placeholder="EMAIL*"
      />

      <button onClick={submit}>
        Submit
      </button>

      <div className="legalText">
      <span>World Data Lab (WDL) is an Austrian not-for-profit organization responsible for building and maintaining the African Poverty Clock amongst other similar projects.  If you fill out the form below your information will be used to 1) send you updates about improvements to the clock as they become available 2) send you articles about the Poverty Clock, as well as other similar projects that WDL is working on 3) create an internal database of users interested in learning how to access African Poverty Clock datasets and related products.  At no time will WDL sell or share your data to any non-related entity.  You have the right to remove your data from our database by clicking on the "unsubscribe" link at the bottom of any email you receive in the future or to write to World Data Lab requesting to be unsubscribed from our database at any time at the following email address:: wpc@worlddata.io. </span>
      </div>
    </div>
  );
};

export default function SubscribeForm(props) {
  return (
    <StyledSubscribeForm>
      <h1>Thank you for your interest</h1>
      <p>We are working on expanding features of the Poverty Clock. Please leave your email to get notified when this is ready</p>
      <MailchimpSubscribe
        url={'//worlddata.us13.list-manage.com/subscribe/post?u=3840f1496862a7a719676d23a&id=72179f20ac'}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
            modalTrigger={props.modal[1]}
          />
        )}

      />
    </StyledSubscribeForm>
  )
}
const StyledSubscribeForm = styled.div`
  .mailchimpForm{
    margin: 1em 1.5em 0 1.5em;
    input{
      display: block;
      width: 100%;
      padding: 1em 0;
      font-size: 1.5rem;
      margin: 1.5em 0;
      border: none;
      border-bottom: 1px solid #000;
      ::placeholder,
      ::-webkit-input-placeholder {
        text-transform: uppercase;
        color: #000;
      }
    }
    button{
      border-radius: 5px;
      box-shadow: 0 2px 10px 0 #00000080;
      background-color: #ee4322;
      color: white;
      font-weight: bold;
      padding: .5em 4em;
      margin-top: 2em;
    }
    .legalText{
      margin-top: 1.5em;
      text-align: left
    }
  }
`
