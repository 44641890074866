import React, {Component} from 'react';
import Header from '../components/Header';
import Loader from './Loading';
import Headline from './Headline';
import Intro from './Intro';
import Modal from '../components/Modal'
import styled from 'styled-components';
import {CSSTransition} from 'react-transition-group'
import { withRouter } from "react-router-dom";

const fadeDuration = 2000;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      clockData: props.clockData
    }

    this.counting();
  }

  counting() {
    setTimeout(() => {
      this.setState({
        isLoaded: true
      })
    }, 5000)
  }

  render() {
    return (
      <StyledHome>
        <Header clockData={this.props.clockData} handleModal={this.props.handleModal}/>
        <CSSTransition
          in={!this.state.isLoaded}
          timeout={fadeDuration}
          unmountOnExit={true}
          classNames="loading"
          onExit={()=> {
            if(this.props.history.location.pathname === '/' && !window.matchMedia('screen and (min-width:768px)').matches){
              this.props.history.push('/headline');
            }
          }}
        >
          <Loader clockData={this.props.clockData} />
        </CSSTransition>
        {
          window.matchMedia('screen and (min-width:768px)').matches
          ? (<div><Intro  {...this.props} /><Modal {...this.props} /></div>)
          : (<Headline  {...this.props} />)
        }
      </StyledHome>
    );
  }
}

const StyledHome = styled.div`
  .loading {
    padding-top: 5rem;
    padding-bottom: 5rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    overflow: auto;

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transition: opacity ${fadeDuration/2}ms ease-out;
      transition-delay: ${fadeDuration/2}ms;
    }
  }
`
export default withRouter(Home);
