import React from 'react';
import Wrapper from '../components/Wrapper';
import Socials from '../components/Socials';
import Logo from '../_/img/logo-white.svg';
import IfadLogo from '../_/img/ifad_logo.png';
import BmzLogo from '../_/img/bmz_logo.png';
import UnecaLogo from '../_/img/uneca_logo.png';
import styled from 'styled-components';

function Footer(props) {
  return (
    <StyledFooter {...props} className="footer">
      <div className="footer-top">
        <Wrapper maxWidth="120rem">
          <h2 className="footer-copyrights">
            <p>African Poverty Clock</p>
            <span className="footer-label">By</span>
            <div className="footer-logo">
              <img src={Logo} alt="African Poverty Clock logo." className="footer-logo-img"/>
              <span className="footer-logo-type label">World Data Lab</span>
            </div>
          </h2>

          <div className="footer-contact">
            <h3 className="footer-contact-heading">Contact us</h3>
            <a href="mailto:wpc@worlddata.io" className="footer-contact-link">wpc@worlddata.io</a>
            <Socials />
            
          </div>
          <div className="footer-bottom">
              <h2 className="footer-label">supported by</h2>
              <div className="footer-bottom-container">
                <img width="160" className="footer-bottom-container-item" src={IfadLogo} alt=""/>
                <img width="180" className="footer-bottom-container-item" src={BmzLogo} alt=""/>
              </div>
              <br /><br />
              <h2 className="footer-label">African Poverty Clock<br />supported by:</h2>
              <div className="footer-bottom-container">
                <img width="180" className="footer-bottom-container-item" src={UnecaLogo} alt=""/>
              </div>
          </div>
          <div className="footer-bottom">
              
          </div>
        </Wrapper>
      </div>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  margin-top: 18rem;
  background-color: #EE4422;
  .footer {

    &-top {
      padding-top: 30vh;
      padding-bottom: 8rem;
      background-color: #EE4422;
      color: #fff;

      .wrapper {
        display: flex;
      }
    }

    &-label {
      display: block;
      margin-bottom: 1.5em;
      font-size: .75em;
    }

    &-copyrights {
      flex: 0 0 20em;
      margin-right: 1em;
      p{
        font-size: 45px;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 0.25em
      }
    }

    &-logo {
      display: flex;
      align-items: center;
      font-size: .875em;

      &-img {
        max-width: 2.5em;
        margin-right: 1em;
      }
    }

    &-contact {
      font-size: .875em;
      border-right: 1px solid;
      padding-right: 4em;

      &-heading {
        margin-bottom: 1em;
        font-weight: bold;
        text-transform: uppercase;
      }

      &-link {
        display: inline-block;
        margin-bottom: 1em;
      }
    }

    &-socials {
      display: flex;

      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.5em;
        width: 2.5em;
        padding: .5em;
        background-color: currentColor;
        border-radius: 50vw;

        > img {
          max-width: 100%;
          max-height: 100%;
        }

        &:not(:last-child) {
          margin-right: 1em;
        }
      }
    }

    &-bottom {
      margin-left: 4.5em;

      &-container {
        display: flex;
        align-items: flex-start;

        &-item {
          &:not(:last-child) {
            margin-right: 2em;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 0;
    .footer {
      
      &-top {
        padding-top: 3em;
        padding-bottom: 1em;
        .wrapper {
          display: block;
        }
      }

      &-copyrights {
        margin-bottom: 3em;
      }
      &-contact{
        border-right: 0;
      }
      &-bottom {
        margin-left: 0;
        margin-top: 2.5em;
        &-container {
          display: block;
          img{
            margin-bottom: 1em;
          }
        }
      }
    }
  }
`

export default Footer;
