import React, {Component} from 'react';
import {default as ReactSelect,  createFilter } from 'react-select';
import styled from 'styled-components';



export default class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selectedOption: ''//{value: 'all countries', label: 'all countries'},
    }
  }

  handleChange = selectedOption => {
    this.props.toggleScroll(true);
    this.setState({ selectedOption });
    if (this.props.onChange) {
      this.props.onChange(selectedOption.value);
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.selectedOption.value) {
      return {
        selectedOption: {value: props.value, label: props.value}
      };
    }

    return null;
  }


  render() {
    const { selectedOption } = this.state;
    const filterConfig = {matchFrom: 'start'}
    return (
      <StyledSelect className="select" onMouseOver={() => this.props.toggleScroll(false)} onMouseLeave={() => this.props.toggleScroll(true)}>
        <ReactSelect
          className="select"
          classNamePrefix="react-select"
          value={selectedOption}
          onChange={this.handleChange}
          options={ (this.props.options) ? this.props.options : ["Loading","2"] }
          placeholder={this.props.placeholder}
          menuPlacement={"auto"}
          matchFrom="start"
          filterOption={createFilter(filterConfig)}
        />
        <span className="select-icon"></span>
      </StyledSelect>
    );
  }
}


const StyledSelect = styled.div`
   z-index: 11;
   position: relative;
   max-width: 12em;
   

  .react-select__option{
    color: rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid grey;
    padding-left: 0px;
    :hover{
      background-color: #F0F0F0 ;
    }
  }

  .react-select__option--is-active{
    background-color: WhiteSmoke ;
  }
  .react-select__option--is-focused{
    background-color: white ;
  }
  .react-select__option--is-selected{
    background-color: WhiteSmoke ;
  }

  .react-select__placeholder{
    color: black;
  }

  .react-select__control--menu-is-open{
    border-color: white;
  }
  .react-select__control--is-focused{
    border: 0px;
    border-color: white;
    background-color: white;
  }


  .react-select__menu-list{
  }

  .react-select__control{
    border-color: white;
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid black;
  }
  
  .react-selec__control:hover {
    border-color: white;
  }
  .react-select__control:select {
    border-color: white;
  }

  .react-select__menu {
    width: 288px;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    padding: 11px 19px;
  }
  .select {
    color: black;
    &-selected {

      border-bottom: 1px solid;
    }
  }
  @media screen and (max-width: 768px) {
     max-width: 100%;
   }
`
