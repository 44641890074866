import React, {Component} from "react";
import {LogoIcon} from '../assets/Logo';
import {Link} from 'react-router-dom';
import Burger from '../components/Burger';
import Wrapper from '../components/Wrapper';
import Modal from '../components/Modal';
import Footer from '../components/Footer';
import {StyledMenu} from '../templates/Menu';
import styled from 'styled-components';

export default class Article extends Component {
    constructor(props){
        super(props);
        this.props.handleModal(false,'');
        this.state = {
            currentPage: this.props.page
        }
    }
    getArticleContent = () => {
        switch (this.props.page) {
            case 'about':
                return(
                    <>
                    <h1>About</h1>
                    <h6>ABOUT POVERTY CLOCK</h6>
                    <p>The African Poverty Clock provides real-time poverty estimates through 2030 for nearly every country in the world. It monitors progress against Ending Extreme Poverty, which is the UN&rsquo;s first Sustainable Development Goal (SDG). The escape rate calculates the current rate of poverty reduction in the world.</p>
                    <p>The African Poverty Clock was developed by World Data Lab, a global data enterprise. The Clock&rsquo;s underlying dataset draws upon a standardized global poverty database which provides real-time projections on income levels for every individual in the world. The core modeling methodology of the African Poverty Clock has been peer-reviewed by the academic community and published on the website of <a href="https://www.nature.com/articles/s41599-018-0083-y">Nature</a>.</p>
                    <p>Funded by the International Fund for Agricultural Development (IFAD) and the Federal Ministry for Economic Cooperation and Development of Germany, the African Poverty Clock shows the number of people living in extreme poverty worldwide, disaggregated by age, gender and rural/urban location. Every second, some escape while others fall into poverty. We count them. We also count &ldquo;excess&rdquo; poverty &ndash; the gap between the actual number of people who have escaped poverty since end-December 2015 and the hypothetical number who should have escaped in order for the world to be on-track to reach the global target of ending poverty by 2030. The &ldquo;Target Escape Rate&rdquo; is the average poverty reduction (per second) needed to achieve SDG1 by the end of 2030. The &ldquo;Current Escape Rate&rdquo; is the current speed of poverty reduction between the year before and the year after the current year.</p>
                    <p>For more information on the team behind World Data Lab, please visit <a href="http://www.worlddata.io">www.worlddata.io</a>.</p>
                    </>
                );
            case 'methodology':
                return(
                    <>
                    <h1>Methodology</h1>
                    <p><strong>Update Version: </strong>The African Poverty Clock dataset was updated as of November 2021, taking into consideration the COVID-19 pandemic effects in the economy. All databases and sources mentioned in this section were last drawn upon in October and November of 2019.</p>
                    <p>The methodology of the African Poverty Clock has been peer-reviewed in the academic community and published on the website of <a href="https://www.nature.com/articles/s41599-018-0083-y">Nature</a>. Overall, the approaches make use of Lorenz curves which have proven successful in depicting populations&rsquo; income or spending distribution in&nbsp;a large number of&nbsp;scientific analyses and are generally accepted as a representation of the inequality in wealth distributions.</p>
                    <p>Using these models, we compute the speed of poverty reduction in each country and region and compare it to the average speed needed to end poverty by 2030. The comparison allows for a categorization of countries into four classes: No Extreme Poverty, On-Track, Off-rack, Poverty is Rising.</p>
                    <p>For questions regarding our methodology, please feel free to contact <a href="mailto:data@worlddata.io">data@worlddata.io</a></p>
                    <p><strong>National poverty estimates:</strong> The African Poverty Clock uses publicly available data on income distribution, production and consumption provided by various international organizations, including the World Bank&rsquo;s PovCal database and the International Monetary Fund. These organizations compile data provided to them by the local governments. When governments fail to provide data, we use specifically developed models to estimate poverty in these countries. Our data covers 99.7% of the world&rsquo;s population. We also model how individual incomes might change over time using IMF growth forecasts for the medium term complemented by long-term &ldquo;shared socio-economic pathways&rdquo; developed by the International Institute of Applied Systems Analysis (IIASA) and similar analysis developed by the OECD.</p>
                    <p><strong>Rural/Urban poverty estimates:</strong> The World Bank&rsquo;s PovCal dataset includes rural and urban poverty head count ratios at respective nationally defined poverty lines as well as information regarding rural and urban shares of the total population.&nbsp;In the African Poverty Clock&rsquo;s methodology, these headcount ratios are combined with the rural and urban shares of the population to get the share of poor people that are rural and urban.&nbsp;We implicitly assume that those shares are the same at the extreme poverty line of 1.90 PPP USD and train a model to learn the relationship between rural/urban poverty rates and a set of covariates (poverty share, rural population share, age dependency ratios, education structure). We use this relationship to make predictions about countries where we do not have data on nationally defined rural/urban poverty.&nbsp;The urban population share is taken from World Bank whenever possible, otherwise it&rsquo;s directly taken from the Shared Socioeconomic Pathways (SSP) projections, which improve upon the UN projections in several ways.&nbsp;In order to forecast the urban&nbsp;population&nbsp;share we take either the change rate from the SSPs (if World Bank data exists) or directly the SSP numbers (if we don&rsquo;t have World Bank data).</p>
                    <p><strong>Age/gender poverty estimates.</strong> The data on poverty by age and gender&nbsp;is&nbsp;based World Data Lab&rsquo;s poverty modeling methodology (as listed above) and survey data. The survey data are taken from the International Income Distribution Data Set (I2D2). The decision of using the I2D2 to obtain information on the spending of different demographic groups is&nbsp;based on the fact that&nbsp;it includes harmonized information on over 110 countries.&nbsp;Thus,&nbsp;it is one of the most up-to-date and comprehensive data sets on the topic currently available.&nbsp;</p>
                    <p>In each of the surveys, we group the country&rsquo;s population by age (in five-year age groups) and gender. For every group we compute the mean spending per capita and the spending distribution. To compute individual spending, total household spending is divided by the number of household members.&nbsp;</p>
                    <p>To extract the distribution within each group, we apply an approach based on Datt et al. 1998. Following this, we draw Lorenz curves for each demographic group. The two vectors needed for a Lorenz curve are the cumulative population and the cumulative spending ordered by mean expenditure starting with the poorest. We then fit a beta curve to these vectors. Next, we use OLS to estimate the parameters of each Lorenz curve, defining the spending distribution inside every group in our sample. This means that within every group we know how much each part of the population spends in relation to the rest.&nbsp;</p>
                    <p>The computed distributional parameters are kept constant over time, implying non-dynamic distributions. Instead, overall changes only enter the model through changes in the mean spending.</p>
                    <p>For every country that has at least two surveys which are five years or more apart, we&nbsp; also compute the growth rate of mean spending of each demographic group.&nbsp;We analyze&nbsp;the relative dynamics of growth rates of spending across groups and use the results of the analysis to specify consistent and realistic assumptions on future growth for both group and national spending.&nbsp;To merge these data with our national poverty numbers we compute the share of each demographic group in the population living in poverty. Applying these shares to the WDL national poverty forecasts we get the numbers of people in poverty by age, and gender.&nbsp;</p>
                    <p><strong>Age/gender poverty estimates for countries with missing data</strong>. The main idea of this approach is to match the survey information we have to countries with no available I2D2 data. To do so, we use four sets of variables which summarize aspects related to demography and socioeconomic characteristics: (i) the shares of five-year age groups in the total population by gender, (ii) the shares of population employed in primary, secondary and tertiary sector, (iii) the shares of population by educational attainment (primary, secondary, and tertiary), and (iv) GDP per capita. We do this for every country.&nbsp;</p>
                    <p>The data for education and age structure are sourced from the IIASA. GDP per capita in constant 2011 PPPs comes from the World Economic Outlook (WEO) and employment by sector is sourced from the World Bank&rsquo;s World Development Indicators (WDI) database.&nbsp;</p>
                    <p>Based on this set of variables we try to find the countries that are the most similar, the idea being that countries that are similar in that respect also have similar demographic structures in the poor population. We then impose their spending distributions, anchored at the national expenditure of the missing countries. This method is based on Crespo&nbsp;Cuaresma&nbsp;et al. 2019.&nbsp;</p>
                    <p>This method allows us to produce a complete set of forecasts of the numbers of persons in poverty by age and gender for almost all countries of the world until 2032.&nbsp;The overall approach on finding similar countries and using information on their spending distributions for countries with little input data is in the process of being peer-reviewed and is expected to be published soon.&nbsp;</p>
                    <p><strong>SELECTED COUNTRIES</strong></p>
                    <p><strong>China</strong></p>
                    <p>The base survey for China is from 2015. It is administered by the government National Bureau of Statistics. Summary statistics on cost-of-living and income are made available for rural and urban populations separately. There may, additionally, be significant regional variation in cost-of-living, but we do not have data to adjust for this.</p>
                    <p>China also participated in the 2011 International Price Comparisons project. This data is used to convert domestic consumption estimates into internationally-comparable prices.</p>
                    <p>The Chinese Household Surveys report on both income and consumption. Officially, China measures domestic poverty using income data. However, we use consumption data, in accordance with the standard practice at the World Bank that favors the use of consumption data over income data for poverty measurement when both are available. Poverty measured using consumption data is far higher in China than poverty measured using income data as even the poorest Chinese households report large household savings.</p>
                    <p>Data is sourced from the World Bank&rsquo;s PovcalNet website.</p>
                    <p>Baseline data for 2015 and forecast assumptions:</p>
                    <p>Urban poverty: 0.31% or 7.62 million people</p>
                    <p>Rural poverty: 1.25% or 2.36 million people</p>
                    <p>Mean urban household income per capita: $5017.68 (2011 PPP dollars)</p>
                    <p>Mean rural household income per capita: $2697.84 (2011 PPP dollars)</p>
                    <p>Mean household per capita consumption growth 2015-2020: 6.5%</p>
                    <p>Mean household per capita consumption growth 2020-2023: 5.8%</p>
                    <p>Mean household per capita consumption growth 2023-2030: 5.1%</p>
                    <p><strong>Democratic Republic of Congo</strong></p>
                    <p>The base survey for the Dem. Rep. Congo is from 2013. It is a national survey that collects data on household consumption. No corrections are made to account for cost-of-living or other price differentials between different parts of the country.</p>
                    <p>Estimating poverty in the DRC is fraught with challenges. There is a significant number of displaced people, estimated by the U.N. to number 2.3 million, following years of conflict. Equally, there are many DRC nationals who are refugees in other countries. The large flows of people in and out of the country, with considerable upheaval in their circumstances, make even basic population and household income data in the DRC hard to estimate. The situation is compounded by civil unrest and a difficult political situation resulting from the postponement of elections.</p>
                    <p>The DRC economy relies heavily on mineral extraction and is therefore susceptible to swings in commodity terms of trade. It enjoyed rapid growth of 7.7% between 2010-2015 but since then growth has slowed. Because of this pattern of growth, the link between aggregate GDP and household consumption is weaker than in other countries. Furthermore, because growth based on mineral extraction is inherently volatile, basing long-term growth forecasts on past growth is likely to be subject to error.</p>
                    <p>Taken together, the various uncertainties mean that estimates of the levels and forecasts of poverty for DRC should be treated with considerable caution.</p>
                    <p>Data is sourced from the World Bank&rsquo;s PovcalNet website.</p>
                    <p>Baseline data for 2013 and forecast assumptions:</p>
                    <p>National poverty: 76.59% or 52.83 million people</p>
                    <p>Mean household income per capita: $557.64 (2011 PPP dollars)</p>
                    <p>Mean household per capita consumption growth 2013-2020: 1.5%</p>
                    <p>Mean household per capita consumption growth 2020-2023: 1.6%</p>
                    <p>Mean household per capita consumption growth 2023-2030: 2.6%</p>
                    <p><strong>Nigeria</strong></p>
                    <p>For Nigeria, the general household survey (GHS) from 2012/2013 is used, rather than the harmonized Nigeria living standards survey. It is more recent and believed to be of higher quality.</p>
                    <p>The challenges in estimating poverty in Nigeria stem from the fact that Nigeria is not a homogeneous country. There are distinct differences in economic conditions between the South and the North. National averages conceal these differences and surveys are not sufficiently representative at the state level to draw firm conclusions. However, we believe that poverty has fallen over time along with economic growth in Southern states, while it has been more stubborn in northern States. These differences cannot be factored into our national level calculations, although inequality in Nigeria has risen in recent years.</p>
                    <p>Nigeria has also been suffering from civil unrest and conflict in selected Northern areas where Boko Haram has been active. Such unrest will indubitably have a negative impact on poverty, but there is insufficient data to quantify this.</p>
                    <p>Like other oil exporting countries, economic growth and the delivery of public services in Nigeria is highly dependent on the international price of oil.</p>
                    <p>Baseline data for 2013 and forecast assumptions:</p>
                    <p>National poverty: 38.1% or 65.9 million people</p>
                    <p>Mean household income per capita: $1168 (2011 PPP dollars)</p>
                    <p>Mean household per capita consumption growth 2013-2020: -2.1%</p>
                    <p>Mean household per capita consumption growth 2020-2023: 0.3%</p>
                    <p>Mean household per capita consumption growth 2023-2030: 0.3%</p>
                    <p><strong>India</strong></p>
                    <p>The base survey for India is from 2012. It is conducted by the National Sample Survey Office of the Government of India, and data is separately reported for rural and urban areas. The World Bank adjusts the raw data to reflect cost-of-living</p>

                    <p>While there is a long tradition of surveys in India, the gap between mean per capita household consumption from survey data and household expenditure per capita from National Accounts data is among the highest in the world. Over 60 percent of consumption is missing. Much of this can be attributed to the inability of surveys to capture wealthier households, but there are also methodological differences in the way India conducts its surveys that have a material impact.</p>
                    <p>India uses a 30-day recall period for food, rather than the more commonly used 7-day recall period. This imposes a downward bias on reported consumption. (In fact, studies show that a diary method, where expenditures are recorded daily in a diary, offer the most reliable data.) India also does not include an imputation for owner-occupied housing unlike many other countries. Recent studies suggest that the omission of a line item on eating out, food consumed outside the household, is also a major omission.</p>
                    <p>Next year a new survey will be released in India that adjusts some of these methods. The 30-day Universal Recall Period (URP) will be replaced by the Modified Mixed Reference Period (MMRP) which uses different recall periods based on the good. High frequency items like food has a 7-day recall period, while some non-food consumption items have a 1-year recall period.</p>
                    <p>From periods where both methods were used simultaneously,&nbsp;<a href="https://indianexpress.com/article/explained/meaning-urp-mrp-mmrp/">we know</a>&nbsp; that the shorter recall period results in an increase of aggregate consumption by 12% in rural India and 10% in urban areas.</p>
                    <p>Another missing piece of aggregate consumption is the imputed rents of owner-occupied homes. On average, the poorest Indians spend&nbsp;<a href="http://datatopics.worldbank.org/consumption/country/India">5.6%</a>&nbsp;of their total expenditure on housing. Given the&nbsp;<a href="https://www.researchgate.net/publication/319037923_Rural_Housing_in_India_Status_and_Policy_Challenges">ownership rates</a>&nbsp;in rural and urban India of 93.3% and 61.2%, respectively, we can estimate adjustments for the surveys&rsquo; expenditure figures of 5.2% (rural) and 3.4% (urban).</p>
                    <p>To project household expenditure to 2018 we apply the growth rates of Household final consumption expenditure (HFCE) from the national accounts to the latest available survey. A recent paper by the&nbsp;<a href="http://documents.worldbank.org/curated/en/294251537365339600/pdf/130008-WP-Nowcasting-poverty-in-India-PUBLIC.pdf">World Bank</a>&nbsp;uses survey-to-survey imputation to estimate survey-based household expenditure figures for 2015. They conclude that only parts of the increases in the national accounts (HFCE) pass through to households. Rural India&rsquo;s Households expenditure only increases at 73% of the HFCE&rsquo; growth rate. The pass through for urban India is 55.9%.</p>
                    <p>The Baseline data for 2012 from Povcal</p>
                    <p>Mean rural household consumption: 1150 (2011 int. dollars)</p>
                    <p>Mean urban household consumption: 1760 (2011 int. dollars)</p>
                    <p>Annual growth rate of HFCE from 2012 to 2018: 5.6% p.a.</p>
                    <p>Rural poverty rate in 2012: 24.83%</p>
                    <p>Urban poverty rate in 2012: 13.39%</p>
                    <p>Rural poverty rate in 2018: 6.5%</p>
                    <p>Urban poverty rate in 2018: 3.4%</p>

                    <p>Adjusted data for India</p>
                    <p>After adjusting the survey means in 2012 (imputed rents, recall periods) we get</p>
                    <p>Mean rural household consumption: 1355 (2011 int. dollars)</p>
                    <p>Mean urban household consumption: 2002 (2011 int. dollars)</p>
                    <p>Annual rural household consumption growth rate from 2012 to 2018: 4.1%</p>
                    <p>Annual urban household consumption growth rate from 2012 to 2018: 3.1%</p>

                    <p>Rural poverty rate in 2012: 14.05%</p>
                    <p>Urban poverty rate in 2012: 9.49%</p>
                    <p>Rural poverty rate in 2018: 4.25%</p>
                    <p>Urban poverty rate in 2018: 3.79%</p>

                    <p><strong>Indonesia</strong></p>
                    <p>The base survey for Indonesia is from 2014. Core household consumption expenditure is collected by SUSENAS, the national Socioeconomic Survey, a large nationally representative survey.</p>

                    <p>As in the case of China and India, the World Bank makes adjustments to reflect cost-of-living differences between rural and urban areas. Poverty rates are separately computed for households living in these two areas. As is the case in India, there is a large unexplained discrepancy between levels of household consumption inferred from national accounts data and the mean levels reported in survey data. This suggests that poverty estimates for Indonesia should be treated with caution.</p>

                    <p>Data is sourced from the World Bank&rsquo;s PovcalNet website.</p>
                    <p>Baseline data for 2014 and forecast assumptions:</p>
                    <p>Urban poverty: 11.3% or 15.23 million people</p>
                    <p>Rural poverty: 9.8% or 11.7 million people</p>
                    <p>Mean urban household income per capita: $2296 (2011 PPP dollars)</p>
                    <p>Mean rural household income per capita: $1598 (2011 PPP dollars)</p>
                    <p>Mean household per capita consumption growth 2014-2020: 4.2%</p>
                    <p>Mean household per capita consumption growth 2020-2023: 4.6%</p>
                    <p>Mean household per capita consumption growth 2023-2030: 4.6%</p>

                    <p><strong>Estimating poverty for countries without survey data</strong></p>
                    <p>For 22 countries no recent income or consumption surveys are available (or surveys are deemed to be unreliable). For these countries, we use a similar approach the the one outlined in section &ldquo;<strong>Age/gender poverty estimates for countries with missing data</strong>&rdquo; above.</p>
                    <p>The countries without surveys are&nbsp;Afghanistan, United Arab Emirates, Antigua and Barbuda, Bahrain, Brunei Darussalam, Cuba, Eritrea, Equatorial Guinea, Grenada, Kuwait, Lebanon, Libya, Macao, Myanmar, Malaysia, Oman, Democratic People's Republic of Korea, Qatar, Saudi Arabia, Saint Vincent and the Grenadines, Yemen, and Zimbabwe.</p>

                    <p>Syria is the only country for which we have not been able to provide estimates. Given the current conditions in Syria, poverty concepts are not readily applicable.</p>

                    </>
                );   
            default:
                break;
        }
    }
    componentDidUpdate(){
    
        if(this.state.currentPage !== this.props.page){
            this.props.handleModal(false,'');
            this.setState({currentPage: this.props.page});
        }
    }
    componentDidMount(){
        document.body.scrollTop = 0;
    }
    render(){
        return(
            <StyledArticle>
                <StyledMenu>
                <div className="menu-header">
                    <Wrapper maxWidth="110rem">
                        <div className="menu-header-logo">
                            <div className="menu-header-logo-icon">{LogoIcon}</div>
                            <div className="menu-header-logo-title"><Link to="/headline">&lt; Back to the clock</Link></div>
                        </div>
                        <Burger onClick={ () => {this.props.handleModal(true,'topmenu')}}/>
                    </Wrapper>
                    
                </div>
                </StyledMenu>
                <Modal
                    {...this.props}
                />
                <Wrapper maxWidth="110rem" >
                <div className="article-content">
                    {this.getArticleContent()}
                </div>
                </Wrapper>
                <Footer/>
            </StyledArticle>
        );
            
        
    }
}
const StyledArticle = styled.div`
    user-select: auto;    

    .menu-header{
        padding: 2em 4em;
        .wrapper{
            display: flex;
        }
        
        &-logo{
            &-icon{
                margin-right: 2em;
            }
            &-title{
                font-size: .85em;
            }
        }
    }
    .burger { margin-left: auto;}
    h1{
        font-family: 'Berlingske';
        font-size: 2em;
        margin-bottom: 1.5em;
    }
    h6{
        text-transform: uppercase;
        font-weight: 100;
        letter-spacing: 2px;
        font-size: .9em;
        margin-top: .5em
    }
    p{
        margin: 1.25em 0 1.25em 0;
    }
    .article-content{
        a{color: #ee4322}
    }
    .footer-top{
        padding-top: 5em;
    }
    .footer-copyrights{
        p{
            margin: 0;
        }
        
    }
    @media screen and (max-width: 768px){
        .menu-header{
            padding: 0;
            padding-top: .75em;
            padding-bottom: .5em;
            margin-bottom: 1em;
            &-logo{
            &-title{
                padding-top: .35em;
                margin-left: 1em;
                
            }
            &-icon{
                margin-right: 0;
            }
            }
        }
        
    }
   
`