import React, {Component} from 'react';
import ReactDOM from "react-dom";
/*import Logo from '../_/img/logo.svg';*/
import ClockLegend from './ClockLegend';
import * as d3 from 'd3';
import styled from 'styled-components';

const LogoIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 41.8 31.3" className="clock-logo">
  <g>
    <path className="fillSecond" fill="#E8482B" d="M34.2,29.8l7.5-18c0.5-1.2-0.8-2.4-1.9-1.8c-2.1,1-4.8,1.9-7.7,1.9c-3,0-5.7-0.9-7.8-1.9   c-1.2-0.6-2.4,0.6-1.9,1.8l7.5,18C30.6,31.8,33.4,31.8,34.2,29.8z"></path>
    <path className="fillFirst" fill="#09341D" d="M7.6,11.3l-7.5,18c-0.5,1.2,0.8,2.4,1.9,1.8c2.1-1,4.8-1.9,7.7-1.9c3,0,5.7,0.9,7.8,1.9   c1.2,0.6,2.4-0.6,1.9-1.8l-7.5-18C11.2,9.3,8.4,9.3,7.6,11.3z"></path>
    <path className="fillSecond" fill="#E8482B" d="M35.7,3.7C35.7,1.6,34,0,32,0c-2.1,0-3.7,1.7-3.7,3.7c0,2.1,1.7,3.7,3.7,3.7C34.1,7.4,35.7,5.8,35.7,3.7z"></path>
    <path className="fillFirst" fill="#09341D" d="M13.6,3.7c0-2.1-1.7-3.7-3.7-3.7C7.8,0,6.2,1.7,6.2,3.7c0,2.1,1.7,3.7,3.7,3.7S13.6,5.8,13.6,3.7z"></path>
    
  </g>
  </svg>
)

let width = 360;
let height = 360;
let margin = 0;
let radius = Math.min(width, height) / 2 - margin;

class Clock extends Component {
  constructor(props) {
    
    super(props);

    this.state = {
      currAngle: 0,
      startAngle: props.startAngle || .66666,
      chartColors: ["#000000", "#EE4422"], //white tomato: "#F9C6BC"
      filters: [
        [false, false],
        [false, false, false]
      ],
      pData: {},
      pYear: new Date().getFullYear(),
      currentRegion: '',
      firstLoad: true,
      isFiltered: false,
      mapDisplay: false,
    }
  }
  static defaultProps = {
        refreshing: false,
        isCountryCard: false,
    }
  // @Poli – D3.js using method to render charts
  buildClock(clockData, reload=true) {
    let svg = d3.select(this.chart)
    .append("svg")
    .classed("clock-chart", true)
    .attr("width", "100%")
    .attr("height", "100%")
    .attr("viewBox", "0 0 " + width + " " + height)
    .append("g")
    .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")


    let data1 = {a: 0, b: 0}
    let data2 = {a: clockData.population - clockData.headcountInPoverty, b: clockData.headcountInPoverty}
    let transitionDuration = 1500;
    let color = d3.scaleOrdinal()
      .domain(data2)
      .range(this.state.chartColors)

    let update = (data, duration) => {
      let pie = d3.pie()
        .value(function(d) {return d.value; })
        .startAngle(this.state.startAngle * Math.PI)
        .endAngle((this.state.startAngle + 2) * Math.PI);

      let data_ready = pie(d3.entries(data))

      let arc = d3.arc()
        .innerRadius(120)
        .outerRadius(radius)

      let u = svg
        .selectAll('path')
        .data(data_ready)



            u
              .enter()
              .append('path')
              .merge(u)
              .transition()
              .ease(d3.easeQuad)
              .duration(duration)
              .attrTween('d', function(d) {
                let i = d3.interpolate(d.startAngle+0.1, d.endAngle);
                return (t) => {
                  d.endAngle = i(t);
                  return arc(d)
                }
              })
              .attr('fill', function(d){ return(color(d.data.key)) })
              //.style("filter", "url(#drop-shadow)")
      /*}else{
        u
          .enter()
          .append('path')
          .merge(u)
          .transition()
          .ease(d3.easeQuad)
          .duration(duration)
          .attrTween('d', function(d) {
        		let i = d3.interpolate(d.startAngle+0.1, d.endAngle);
        		return (t) => {
        			d.endAngle = i(t);
        			return arc(d)
      			}
      		})
          .attr('fill', function(d){ return(color(d.data.key)) })
      }*/


      svg.attr('height', svg.outerWidth);
    }

    update(data1, 0);
    update(data2, transitionDuration);
  }

  buildChart(clockData,reload=true) {
    
    let svg = d3.select(this.chart)
    .append("svg")
    .classed("clock-chart", true)
    .classed("clock-internal",this.props.isCountryCard)
    .attr("width", "100%")
    .attr("height", "100%")
    .attr("viewBox", "0 0 " + width + " " + height)
    .attr("preserveAspectRatio","xMinYMin meet")
    .append("g")
    .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")

    let data1 = {a: 0, b: 0}
    let data2 = {};
    let transitionDuration = 1500;
    if(!reload){
      transitionDuration = 0
    }
    if( this.props.filters[1][2] && (this.props.filters[1][0] || this.props.filters[1][1]) ) { //Only renders 3rd slice if the age range filter is active
      data2 = {
        a: clockData.population - clockData.headcountInPoverty,
        b: clockData.headcountInPoverty - clockData.headcountInAgeGroup,
        c: clockData.headcountInAgeGroup
      }
      
    } else if( this.props.filters[1][2] ) { //If age range selected, but no other filter, the slice is headcountInAgeGroup

      data2 = {
        a: clockData.population - clockData.headcountInAgeGroup,
        //b: 0,
        c: clockData.headcountInAgeGroup}
    } else {
      
      data2 = {
        a: clockData.population - clockData.headcountInPoverty,
        //b: 0,
        c: clockData.headcountInPoverty}
    }
    //}
    let color = d3.scaleOrdinal()
    .domain(data2)
    .range(["#F9C6BD", "#F3836F", "#EE4322"]);

    let update = (data, duration) => {
      
      let defs = svg.append('defs');
      let filter = defs.append("filter")
          .attr("id", "drop-shadow")
          .attr("height", "120%");

      // SourceAlpha refers to opacity of graphic that this filter will be applied to
      // convolve that with a Gaussian with standard deviation 3 and store result
      // in blur
      filter.append("feGaussianBlur")
          .attr("in", "SourceAlpha")
          .attr("stdDeviation", 4)
          .attr("result", "blur");

      // translate output of Gaussian blur to the right and downwards with 2px
      // store result in offsetBlur
      filter.append("feOffset")
          .attr("in", "blur")
          .attr("dx", 2)
          .attr("dy", 2)
          .attr("result", "offsetBlur");

      // overlay original SourceGraphic over translated blurred opacity by using
      // feMerge filter. Order of specifying inputs is important!
      let feMerge = filter.append("feMerge");

      feMerge.append("feMergeNode")
          .attr("in", "offsetBlur")
      feMerge.append("feMergeNode")
          .attr("in", "SourceGraphic");

      let pie = d3.pie()
        .value(function(d) {return d.value; })
        .startAngle(this.state.startAngle * Math.PI)
        .endAngle((this.state.startAngle + 2) * Math.PI);
      
      let data_ready = pie(d3.entries(data))
      
      let arc = d3.arc()
        .innerRadius(0)
        .outerRadius(radius-15)
      
      let u = svg
        .selectAll('path')
        .data(data_ready)

      u
        .enter()
        .append('path')
        .merge(u)
        .transition()
        .ease(d3.easeQuad)
        .duration(duration)
        .attrTween('d', function(d) {
      		let i = d3.interpolate(d.startAngle+0.1, d.endAngle);
      		return (t) => {
      			d.endAngle = i(t);
      			return arc(d)
    			}
    		})
        .attr('fill', function(d){ return(color(d.data.key)) })
        .style("filter", function(d){ if(d.data.key === 'c') return "url(#drop-shadow)"})

      svg.attr('height', svg.outerWidth);

      svg.append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', radius-2)
      .attr('stroke', this.props.outerCircleColor? this.props.outerCircleColor : 'black')
      .attr('stroke-width', '2px')
      .attr('fill', 'transparent')
  
    }

    update(data1, 0);
    update(data2, transitionDuration);

  }

  arraysEqual(arr1, arr2) {
    if(arr1.length !== arr2.length)
        return false;
    for(var i = arr1.length; i--;) {
        if(arr1[i] !== arr2[i])
            return false;
    }

    return true;
  }
  componentDidUpdate() {
    let reloadAnimation = false;


    if (this.props.clockData === undefined) { return }

    if (this.props.renderChart === false) { return }

    if (this.props.clockData.population !== this.state.pData.population && !this.props.refreshing) { //graph only reacts in these conditions
      
      if(this.props.currentYear === this.state.pYear){
        reloadAnimation = true;
      }
      this.renderChart(reloadAnimation)
      this.setState({ pData: this.props.clockData, pYear: this.props.currentYear },()=>{

      })

      return
    }

    if(this.props.mapDisplay !== this.state.mapDisplay) {
      
      reloadAnimation = false
      this.setState({ mapDisplay: this.props.mapDisplay })
      this.renderChart(reloadAnimation)
      return
    }

    if (JSON.stringify(this.props.filters)!==JSON.stringify(this.state.filters)) {
      
      this.setState({ filters: this.props.filters })
      this.renderChart(false)
    }
    if(this.props.subnationalClock === true && this.props.currentRegion !== this.state.currentRegion){
      
      this.setState({currentRegion: this.props.currentRegion});
      this.renderChart(true);
    }
    if(this.state.firstLoad){
      
      this.setState({firstLoad:false},()=>this.renderChart(true));
    }
  }

  componentDidMount() {
    this.setState({ filters: this.props.filters });
    this.setState({ mapDisplay: this.props.mapDisplay });
    this.setState({ pData: this.props.clockData, pYear: this.props.currentYear });
    this.setState({ currAngle: 0 });
    this.setState({currentRegion:this.props.currentRegion});
  }

  hasActiveFilters () {
    if (this.props.filters === undefined) {
      return;
    }
    let has = false;
    this.props.filters.map( x => {
      x.map( y => {
        if (y) has = true;
        return y;
      });
      return x;
    });
    this.setState({ isFiltered: has });
    return has;
  }

  renderChart(reload=true) {
    let clockElementChildren = ReactDOM.findDOMNode(this).children;
    if (this.props.clockData ) {
      Array.from(clockElementChildren).forEach( element => {
        
        if(element.className.baseVal && element.className.baseVal.includes('clock-chart')){
          element.remove()
        }

      })

      if (this.hasActiveFilters()){//
        
        this.buildChart(this.props.clockData,reload)
        this.setState({
          currAngle: (0) //ticker line
        })
      }else{
        this.buildClock(this.props.clockData,reload)
        this.setState({
          currAngle: (-this.props.clockData.headcountInPoverty / this.props.clockData.population) //ticker line
        })
      }
    }
  }

  render() {
    if (this.chart) {
      if(!window.matchMedia("(min-width: 768px)").matches && window.matchMedia("(orientation: landscape)").matches){
        this.chart.style.height = `${this.chart.clientWidth/2}px`;
      }else{
        this.chart.style.height = `${this.chart.clientWidth}px`;
      }
      
      
    }
    if (this.props.renderChart === false) {//Dont render the chart, until the data has loaded and set renderChart to true on app.js
      return (<div style={{height: '22em'}} ></div>)
    }

    return (
      <StyledClock className="clock" {...this.state} {...this.props} ref={ chart => this.chart = chart }>
        {this.props.hasLogo
          ? <div>{LogoIcon}</div>
          : ''
        }
        { <div className="clock-needle">
            {this.props.hasLegend
            ?
            <ClockLegend startAngle={this.state.startAngle} currAngle={this.state.currAngle} {...this.props}/>
            : ''}
          </div>
        }
      </StyledClock>
    )
  }
}


const StyledClock = styled.figure`
  position: relative;

  .clock {
    &-needle {
      height: 33.33333%;
      width: 2px;
      margin: auto;
      position: absolute;
      top: -8.33333%;
      left: 0;
      right: 0;
      background-color: ${state => state.isFiltered ? `transparent` : `currentColor`};
      transform-origin: 50% 175%;

      transform: rotate(${state => state.isFiltered
        ? (state.startAngle / 2 + state.currAngle) + ( (- state.pData.headcountInPoverty  / state.pData.population) * (1/2) ) + `turn`
        : (state.startAngle / 2 + state.currAngle) + ( (state.pData.headcountInPoverty - state.pData.offTrackHeadcount) / state.pData.population ) + `turn`
      });
      /* transition: ${props => props.noTransition ? `none` : `transform 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955)`}; */
      animation: appear 1s 3s both;
      &:after{
        content: '';
        width: 25vh;
        height: 1px;
        position: absolute;
        display: block;
        transform: rotate(90deg);
        top: 0;
        left: 0;
        margin-top: 10.5vh;
        margin-left: -12.3vh;
        background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);
        background-position: bottom;
        background-size: 5px 1px;
        background-repeat: repeat-x;
        display: ${state => state.isFiltered ? `block` : `none`}
      }
    }

    &-logo {
      max-width: 40%;
      margin: auto;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    &-legend {
      margin-bottom: 2em;
      position: absolute;
      left: ${state => state.isFiltered ? `2em` : `-1.25em`};
      top: ${state => state.isFiltered ? `-2.5em` : `-4em`};
      transform-origin: 1.25em center;
      transition: transform 1.5s, opacity .5s 1.5s;
    }
    &-chart{
      g{
        path{
          &:first-child{
            fill: #09341D;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .clock-legend {
      display: none;
    }
    .clock-needle{
      display: none;
    }
  }
`

export default Clock;
