import React from 'react';
import { Link } from "react-router-dom";
import Wrapper from '../components/Wrapper';
import Counter from '../components/Counter';
import Slider from '../components/Slider';
import Map from '../_/img/africa_map.png';
import Modal from '../components/Modal';
import styled from 'styled-components';


export default function Intro(props) {
  return (
    <StyledIntro className="intro">
      <Modal
          show={props.showModal} handleModal={props.handleModal} page={props.modalPage}
          />
      <Wrapper>
        <div className="intro-content">
          <figure className="intro-bg"></figure>
          <h2 className="intro-heading">
            <Counter clockData={props.clockData}/>
            <span className="label intro-heading-statement">People live in extreme poverty in Africa</span>
          </h2>
          <Link to="/headline/" className="square-button" onClick={() => {props.resetFilters()}}>Explore the data</Link>
        </div>
        <Slider currentYear={props.currentYear} handleYearChange={props.handleYearChange} isNonInteractive={true}/>
      </Wrapper>
    </StyledIntro>
  );
}

const StyledIntro = styled.section`
  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-height: auto;
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .intro {
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0.5;
      z-index: -5;
      background-image: url(${Map});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &-content {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 10rem;
      position: relative;
      text-align: center;
    }

    &-heading {
      margin-bottom: 2em;

      &-statement {
        font-weight: 500;
      }
    }
  }

  .counter {
    margin-bottom: .25em
  }
`
